<template>
  <section class="admin-notifications">
    <div class="admin-notifications__wrapper">
      <div
        v-for="({ id, translation_key, data, severity, link }) in notifications"
        :key="id"
        class="admin-notifications__message"
        :class="{
          'md-normal': !severity || severity === severities.NORMAL.toLowerCase(),
          'md-success': severity === severities.SUCCESS.toLowerCase(),
          'md-warning': severity === severities.WARNING.toLowerCase(),
          'md-alert': severity === severities.DANGER.toLowerCase()
        }"
      >
        {{ $translate(translation_key) + (data ? ":" : "") }}
        <router-link v-if="link" :to="link">{{ $translate("here") }}</router-link>
        <div v-if="data">{{ get_correct_data(data) }}</div>
        <md-button
          class="md-icon-button"
          @click="close_notification(id)"
        >
          <md-icon>close</md-icon>
        </md-button>
      </div>
    </div>
    <md-button
      v-if="Object.keys(notifications).length > 1"
      class="md-primary md-raised admin-notifications__clear"
      @click="close_notification()"
    >
      {{ $translate("notifications.clear_all") }}
    </md-button>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import { default_language } from "../../../Shared/constants/other"
import get_correct_translation_value from "../../../Shared/methods/get_correct_translation_value"
import { remove_store_notification } from "../../constants/endpoints/other"
import { ADMIN_STORE, CONFIGURATION_STORE, severities, USER_STORE } from "../../constants/others_constants"
import { REMOVE_NOTIFICATION, UPDATE_NOTIFICATIONS } from "../../stores/Admin/constants"

export default {
  data() {
    return {
      severities
    }
  },
  computed: {
    ...mapState(ADMIN_STORE, ["notifications"]),
    ...mapState(USER_STORE, ["user_info"]),
    ...mapState(CONFIGURATION_STORE, ["project_config"])
  },
  mounted() {
    window.firebase
      .database()
      .ref(`/projects/${this.project_config.project_id}/notifications`)
      .on("value", (data) => this.update_notification(data.val() || {}))
  },
  methods: {
    ...mapMutations(ADMIN_STORE, {
      remove_notification: REMOVE_NOTIFICATION,
      update_notification: UPDATE_NOTIFICATIONS
    }),
    close_notification(notification_id) {
      remove_store_notification(notification_id)
      this.remove_notification(notification_id)
    },
    get_correct_data(data) {
      if (!data) return ""
      if (typeof data.translations === "string") return data.translations

      return get_correct_translation_value(data, "title", [this.user_info.language_code || default_language])
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .admin-notifications {
    position: fixed;
    top: calc(#{$admin-top-bar-height} + #{$material-size});
    right: 5px;
    max-width: 80vw;
    z-index: $z-index--middle;

    &__wrapper {
      max-height: calc(100vh - #{$admin-top-bar-height} - #{$material-size} - #{$zendesk-button});
      padding: $half-default-size;
      overflow: auto;
    }

    &__message {
      position: relative;
      padding: 15px $material-button-small 15px $default-size;
      border-radius: $border-radius;
      text-align: center;
      font-weight: 600;
      font-weight: bold;
      box-shadow: -3px 5px 20px -8px rgba($pure-black, 0.5);
      margin-bottom: $half-default-size;
      transition: $material-transition;
      cursor: default;

      .md-icon-button {
        position: absolute;
        top: 5px;
        right: 0;
      }

      &:hover {
        transform: scale(0.98);
      }
    }

    &__clear {
      position: absolute !important;
      left: 0;
      right: 0;
      bottom: -20px;
      padding: 5px 20px !important;
      width: fit-content !important;
      margin: auto !important;
    }

    .md-normal i, .md-warning i {
      color: $pure-black !important;
    }
    .md-alert i, .md-success i {
      color: $pure-white !important;
    }
    .md-alert a, .md-success a {
      color: $material-yellow !important;
    }
  }
</style>
