import { render, staticRenderFns } from "./admin_content.vue?vue&type=template&id=7fdbff6d&"
import script from "./admin_content.vue?vue&type=script&lang=js&"
export * from "./admin_content.vue?vue&type=script&lang=js&"
import style0 from "./admin_content.vue?vue&type=style&index=0&id=7fdbff6d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports