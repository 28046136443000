import { translate } from "../../../Shared/translator";

export const tour_buttons = async (first) => ({
  btnNext: {
    text: await translate("next"),
    textColor: "#fff",
    backgroundColor: "#448aff",
  },
  btnBack: {
    text: first ? await translate("close") : await translate("back"),
    textColor: "#a09e9e",
    backgroundColor: "#fff",
  }
})

export const base_tour_config = {
  offset: 20,             // distance from popover to target element
  borderRadius: 3,        // popover border radius
  allowClose: false,       // close on click outside
  highlight: true,        // show overlay
  highlightOffset: 5,     // overlay offset from target element
  keyboard: true,         // enable/disable keyboard control
  width: '500px',         // specify popover's width
  zIndex: 10050,          // specify z-index 
  removeArrow: false,
}
