<template functional>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400.38 400.39"
    style="
      width: 50px;
      height: 30px;
    "
  >
    <title>about icon</title>
    <path d="M1084.26,598.32H792.82a54.47,54.47,0,0,0-54.47,54.47V944.23a54.48,54.48,0,0,0,54.47,54.48h291.44a54.47,54.47,0,0,0,54.47-54.48V652.79A54.47,54.47,0,0,0,1084.26,598.32Zm37.47,345.91a37.52,37.52,0,0,1-37.47,37.48H792.82a37.52,37.52,0,0,1-37.47-37.48V708h366.38Zm0-253.23H755.35V652.79a37.51,37.51,0,0,1,37.47-37.47h291.44a37.51,37.51,0,0,1,37.47,37.47Z" transform="translate(-738.35 -598.32)"/>
    <rect x="183.65" y="221.68" width="35" height="110"/><circle cx="200.65" cy="176.68" r="20"/>
  </svg>

</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss">
</style>
