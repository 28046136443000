<template>
  <div class="side-menu">
    <md-toolbar class="md-transparent" md-elevation="0">
      <span>{{ $translate("side_menu.menu") }}</span>

      <div class="md-toolbar-section-end">
        <md-button class="md-icon-button md-dense" @click="toggle_menu(false)">
          <md-icon>keyboard_arrow_left</md-icon>
        </md-button>
      </div>
    </md-toolbar>

    <!------------------  Products section  ------------------>

    <md-list>
      <md-list-item
        data-cy="products-and-inventory"
        class="side-menu__module-expand"
        id="products-section-menu-tour"
        md-expand
        v-if="permissions.products.module && feature_toggles.products !== false"
      >
        <md-icon>
          <products_icon />
          <md-tooltip md-direction="right">{{ $translate("side_menu.product_admin") }}</md-tooltip>
        </md-icon>
        <span class="md-list-item-text">{{ $translate("side_menu.product_admin") }}</span>

        <md-list slot="md-expand">
          <md-list-item 
            data-cy="products" 
            class="md-inset" 
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === imported}"
            @click="() => {
              $router.push(`/admin/products/${imported}`)
              event_hub.$emit('clear_selection')
              update_active_icon(imported)
            }"
          >
            <md-icon class="side-menu__products-section-icons">
              widgets
              <md-tooltip md-direction="right">{{ $translate("side_menu.imported") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.imported_message") }}</span>
          </md-list-item>
          <md-list-item
            v-if="(shopify_config || {}).should_use_shopify_data"
            class="md-inset"
            @click="() => {
              $router.push(`/admin/products/${shopify}`); event_hub.$emit('clear_selection')
            }"
          >
            <md-icon>
              <shopify_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.shopify") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.shopify_message") }}</span>
          </md-list-item>
          <md-list-item data-cy="inventory" 
          class="md-inset" 
          :class="{ 'side-menu__active-icon': current_active_side_menu_icon === inventory}"
          @click="$router.push(`/admin/products/${inventory}`); update_active_icon(inventory)">
            <md-icon class="side-menu__products-section-icons">
              manage_search
              <md-tooltip md-direction="right">{{ $translate("side_menu.inventory") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.inventory") }}</span>
          </md-list-item>
        </md-list>
      </md-list-item>

      <!------------------  Website content section  ------------------>

      <md-list-item
        class="side-menu__module-expand"
        md-expand
        v-if="permissions.layout_and_content.module && feature_toggles.layout_and_content !== false"
        id="store-layout-menu-tour"
        data-cy="content"
      >
        <md-icon>
          web
          <md-tooltip md-direction="right">{{ $translate("side_menu.store_layout_admin") }}</md-tooltip>
        </md-icon>
        <span class="md-list-item-text">{{ $translate("side_menu.store_layout_admin") }}</span>

        <md-list slot="md-expand">
          <md-list-item
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === menu }"
            data-cy="menu-content"
            class="md-inset"
            @click="$router.push(`/admin/content/${menu}`); update_active_icon(menu)"
          >
            <md-icon class="side-menu__content-section-icons">
              <header_icon/>
              <md-tooltip md-direction="right">{{ $translate("side_menu.menu_content") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.menu_content") }}</span>
          </md-list-item>

          <md-list-item
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === important_message_bar }"
            data-cy="important-message-bar"
            class="md-inset"
            @click="$router.push(`/admin/content/${important_message_bar}`); update_active_icon(important_message_bar)"
          >
            <md-icon class="side-menu__content-section-icons">
              <important_message_bar_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.important_message_bar") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.important_message_bar") }}</span>
          </md-list-item>

          <md-list-item
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === special_message_banner }"
            data-cy="special-message-bar"
            class="md-inset"
            @click="$router.push(`/admin/content/${special_message_banner}`); update_active_icon(special_message_banner)"
          >
            <md-icon class="side-menu__content-section-icons">
              <special_message_banner_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.special_message_banner") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.special_message_banner") }}</span>
          </md-list-item>

          <md-list-item
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === popular_products}"
            data-cy="popular-products"
            class="md-inset"
            @click="$router.push(`/admin/content/${popular_products}`); update_active_icon(popular_products)"
          >
            <md-icon class="side-menu__content-section-icons">
              <popular_products_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.popular_products") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.popular_products") }}</span>
          </md-list-item>

          <md-list-item
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === collection_banner }"
            data-cy="collection-banner"
            class="md-inset"
            @click="$router.push(`/admin/content/${collection_banner}`); update_active_icon(collection_banner)"
          >
            <md-icon class="side-menu__content-section-icons">
              <collection_banner_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.collection_banner") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.collection_banner") }}</span>
          </md-list-item>

          <md-list-item
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === story_banner }"
            data-cy="menu-story-banner"
            class="md-inset"
            @click="$router.push(`/admin/content/${story_banner}`); update_active_icon(story_banner)"
          >
            <md-icon class="side-menu__content-section-icons">
              <story_banner_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.story_banner") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.story_banner") }}</span>
          </md-list-item>

          <md-list-item
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === highlighted_products}"
            data-cy="highlighted-products"
            class="md-inset"
            @click="$router.push(`/admin/content/${highlighted_products}`); update_active_icon(highlighted_products)"
          >
            <md-icon class="side-menu__content-section-icons">
              <highlighted_products_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.highlighted_products") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.highlighted_products") }}</span>
          </md-list-item>

          <md-list-item
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === footer }"
            data-cy="footer-content"
            class="md-inset"
            @click="$router.push(`/admin/content/${footer}`); update_active_icon(footer)"
          >
            <md-icon class="side-menu__content-section-icons">
              <footer_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.footer_content") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.footer_content") }}</span>
          </md-list-item>

          <md-list-item
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === about_page}"
            data-cy="about-page"
            class="md-inset"
            @click="$router.push(`/admin/content/${about_page}`); update_active_icon(about_page)"
          >
            <md-icon class="side-menu__content-section-icons side-menu__content-section-icons--about">
              <about_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.about_page") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.about_page") }}</span>
          </md-list-item>
        </md-list>
      </md-list-item>

      <!------------------  Config section  ------------------>

      <md-list-item
        class="side-menu__module-expand"
        md-expand
        v-if="permissions.configuration.module && feature_toggles.configuration !== false"
        id="store-config-menu-tour"
        data-cy="configuration"
      >
        <md-icon>
          build
          <md-tooltip md-direction="right">{{ $translate("side_menu.store_config_admin") }}</md-tooltip>
        </md-icon>
        <span class="md-list-item-text">{{ $translate("side_menu.website_configuration") }}</span>

        <md-list slot="md-expand">
          <md-list-item
            data-cy="translations"
            v-if="permissions.configuration.sections.translations.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === translations}"
            @click="$router.push(`/admin/configuration/${translations}`); update_active_icon(translations)"
          >
            <md-icon>
              <md-icon class="side-menu__config-section-icons">translate</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.translations") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.translations") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="legal-translations"
            v-if="permissions.configuration.sections.translations.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === legal_translations}"
            @click="$router.push(`/admin/configuration/${legal_translations}`); update_active_icon(legal_translations)"
          >
            <md-icon>
              <md-icon class="side-menu__config-section-icons">auto_stories</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.legal_translations") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.legal_translations") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="microsite-translations"
            v-if="permissions.configuration.sections.translations.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === miscrosite_translations}"
            @click="$router.push(`/admin/configuration/${miscrosite_translations}`); update_active_icon(miscrosite_translations)"
          >
            <md-icon>
              <md-icon class="side-menu__config-section-icons">subtitles</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.miscrosite_translations") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.miscrosite_translations") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="style-configuration"
            v-if="permissions.configuration.sections.styling.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === style_config}"
            @click="$router.push(`/admin/configuration/${style_config}`); update_active_icon(style_config)"
            id="store-styling-menu-tour"
          >
            <md-icon>
              <md-icon class="side-menu__config-section-icons">format_paint</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.style_config") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.style_config") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="splash-image"
            v-if="permissions.configuration.sections.styling.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === splash_image_config}"
            @click="$router.push(`/admin/configuration/${splash_image_config}`); update_active_icon(splash_image_config)"
          >
            <md-icon class="side-menu__config-section-icons">
              <splash_image_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.splash_image_config") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.splash_image_config") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="logo-upload"
            v-if="permissions.configuration.sections.styling.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === logo_upload}"
            @click="$router.push(`/admin/configuration/${logo_upload}`); update_active_icon(logo_upload)"
          >
            <md-icon>
              <md-icon class="side-menu__config-section-icons">stars</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.logo_upload") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.logo_upload") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="shopify-configuration"
            v-if="permissions.configuration.sections.shopify.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === shopify}"
            @click="$router.push(`/admin/configuration/${shopify}`); update_active_icon(shopify)"
          >
            <md-icon class="side-menu__config-section-icons">
              <shopify_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.shopify_config") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.shopify_config") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="shipping-configuration"
            v-if="permissions.configuration.sections.shipping.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === shipping}"
            @click="$router.push(`/admin/configuration/${shipping}`); update_active_icon(shipping)"
          >
            <md-icon>
              <md-icon class="side-menu__config-section-icons">local_shipping</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.shipping_options_config") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.shipping_options_config") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="payment-configuration"
            v-if="permissions.configuration.sections.payment_gateway.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === payment_gateway}"
            @click="$router.push(`/admin/configuration/${payment_gateway}`); update_active_icon(payment_gateway)"
          >
            <md-icon>
              <md-icon class="side-menu__config-section-icons">payment</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.payment_gateway_config") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.payment_gateway_config") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="project-configuration"
            v-if="permissions.configuration.sections.project_configuration.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === project}"
            @click="$router.push(`/admin/configuration/${project}`); update_active_icon(project)"
          >
            <md-icon>
              <md-icon class="side-menu__config-section-icons">settings</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.project_config") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.project_config") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="misc-config"
            v-if="permissions.configuration.sections.other_configuration.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === rest}"
            @click="$router.push(`/admin/configuration/${rest}`); update_active_icon(rest)"
          >
            <md-icon>
              <md-icon class="side-menu__config-section-icons">miscellaneous_services</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.rest_config") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.rest_config") }}</span>
          </md-list-item>
        </md-list>
      </md-list-item>

      <!------------------  Financials section  ------------------>

      <md-list-item
        v-if="permissions.finance.module && feature_toggles.finance !== false"
        data-cy="finance"
        class="side-menu__module-expand"
        id="finance-menu-tour"
        md-expand
      >
        <md-icon>
          <finance_icon />
          <md-tooltip md-direction="right">{{ $translate("side_menu.finance") }}</md-tooltip>
        </md-icon>
        <span class="md-list-item-text">{{ $translate("side_menu.finance")  }}</span>

        <md-list slot="md-expand">
          <md-list-item
            data-cy="orders"
            v-if="permissions.finance.sections.orders.module"
            class="md-inset"
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === orders}"
            @click="$router.push(`/admin/finance/${orders}`); update_active_icon(orders)"
          >
            <md-icon>
              <md-icon class="side-menu__financials-section-icons">view_list</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.orders") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.orders") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="discounts"
            v-if="permissions.finance.sections.discounts.module"
            class="md-inset" 
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === discounts}"
            @click="$router.push(`/admin/finance/${discounts}`); update_active_icon(discounts)"
          >
            <md-icon class="side-menu__financials-section-icons">
              <discount_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.discounts") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.discounts") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="finacial-analytics"
            v-if="permissions.finance.sections.analytics.module"
            class="md-inset" 
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === analytics}"
            @click="$router.push(`/admin/finance/${analytics}`); update_active_icon(analytics)"
          >
            <md-icon>
              <md-icon class="side-menu__financials-section-icons">bar_chart</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.analytics") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.analytics") }}</span>
          </md-list-item>
        </md-list>
      </md-list-item>

      <!------------------  Social media section  ------------------>

      <md-list-item
        v-if="permissions.social.module && feature_toggles.social !== false"
        data-cy="social-media"
        class="side-menu__module-expand"
        md-expand
      >
        <md-icon>
          <md-icon>share</md-icon>
          <md-tooltip md-direction="right">{{ $translate("side_menu.social") }}</md-tooltip>
        </md-icon>
        <span class="md-list-item-text">{{ $translate("side_menu.social")  }}</span>

        <md-list slot="md-expand">
          <md-list-item
            data-cy="manage-accounts"
            v-if="permissions.social.sections.accounts.module"
            class="md-inset" 
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === accounts}"
            @click="$router.push(`/admin/social/${accounts}`); update_active_icon(accounts)"
          >
            <md-icon>
              <md-icon class="side-menu__social-media-section-icons">manage_accounts</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.accounts") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.accounts") }}</span>
          </md-list-item>
          <md-list-item
            data-cy="posts"
            v-if="permissions.social.sections.posts.module"
            class="md-inset" 
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === posts}"
            @click="$router.push(`/admin/social/${posts}`); update_active_icon(posts)"
          >
            <md-icon>
              <md-icon class="side-menu__social-media-section-icons">forum</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.posts") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.posts") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="ads-management"
            v-if="permissions.social.sections.advertising.module"
            class="md-inset" 
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === advertising}"
            @click="$router.push(`/admin/social/${advertising}`); update_active_icon(advertising)"
          >
            <md-icon class="side-menu__social-media-section-icons">
              <advertising_icon />
              <md-tooltip md-direction="right">{{ $translate("side_menu.advertising") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.advertising") }}</span>
          </md-list-item>

          <md-list-item
            data-cy="marketing-analytics"
            v-if="permissions.social.sections.marketing_analytics.module"
            class="md-inset" 
            :class="{ 'side-menu__active-icon': current_active_side_menu_icon === marketing_analytics}"
            @click="$router.push(`/admin/social/${marketing_analytics}`); update_active_icon(marketing_analytics)"
          >
            <md-icon>
              <md-icon class="side-menu__social-media-section-icons">bar_chart</md-icon>
              <md-tooltip md-direction="right">{{ $translate("side_menu.marketing_analytics") }}</md-tooltip>
            </md-icon>
            <span class="md-list-item-text">{{ $translate("side_menu.marketing_analytics") }}</span>
          </md-list-item>
        </md-list>
      </md-list-item>

      <md-list-item data-cy="support" class="md-inset side-menu__support" @click="$router.push(`/admin/user/support`)">
        <md-icon>
          support_agent
          <md-tooltip md-direction="right">{{ $translate("user.support") }}</md-tooltip>
        </md-icon>
        <span class="md-list-item-text">{{ $translate("user.support") }}</span>
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import { TOGGLE_MENU, UPDATE_ACTIVE_SIDE_MENU_ICON } from "../../stores/Admin/constants"
import shopify_icon from "../../../Shared/components/icon_components/shopify-icon"
import finance_icon from "../../../Shared/components/icon_components/finance-icon"
import discount_icon from "../../../Shared/components/icon_components/discount"
import products_icon from "../../../Shared/components/icon_components/product-icon"
import header_icon from "../../../Shared/components/icon_components/header-icon"
import advertising_icon from "../../../Shared/components/icon_components/advertising-icon"
import footer_icon from "../../../Shared/components/icon_components/footer-icon"
import about_icon from "../../../Shared/components/icon_components/about-icon"
import special_message_banner_icon from "../../../Shared/components/icon_components/special-message-banner-icon"
import collection_banner_icon from "../../../Shared/components/icon_components/collection-banner-icon"
import popular_products_icon from "../../../Shared/components/icon_components/popular-products-icon"
import highlighted_products_icon from "../../../Shared/components/icon_components/highlighted-products-icon"
import story_banner_icon from "../../../Shared/components/icon_components/story-banner-icon"
import important_message_bar_icon from "../../../Shared/components/icon_components/important-message-bar-icon"
import splash_image_icon from "../../../Shared/components/icon_components/splash-image-icon"
import {
  imported, shopify, menu, collection_banner,
  special_message_banner, popular_products,
  footer, ADMIN, highlighted_products,
  story_banner, important_message_bar,
  translations, miscrosite_translations,
  legal_translations,
  style_config, splash_image_config,
  logo_upload, orders, shipping,
  rest, project, payment_gateway,
  about_page, USER_STORE, discounts,
  accounts, posts, analytics, advertising,
  inventory, marketing_analytics,
  CONFIGURATION_STORE,
} from "../../constants/others_constants"
import { event_hub } from "../../../../main"

export default {
  components: {
    shopify_icon,
    products_icon,
    finance_icon,
    header_icon,
    footer_icon,
    about_icon,
    special_message_banner_icon,
    collection_banner_icon,
    popular_products_icon,
    highlighted_products_icon,
    story_banner_icon,
    important_message_bar_icon,
    splash_image_icon,
    discount_icon,
    advertising_icon,
  },
  data() {
    return {
      imported,
      shopify,
      menu,
      collection_banner,
      special_message_banner,
      footer,
      popular_products,
      highlighted_products,
      story_banner,
      important_message_bar,
      translations,
      legal_translations,
      miscrosite_translations,
      style_config,
      splash_image_config,
      logo_upload,
      shipping,
      advertising,
      orders,
      rest,
      project,
      payment_gateway,
      about_page,
      discounts,
      accounts,
      posts,
      analytics,
      marketing_analytics,
      inventory,
      event_hub
    }
  },
  computed: {
    ...mapState(ADMIN, ["feature_toggles", "current_active_side_menu_icon"]),
    ...mapState(USER_STORE, ["permissions"]),
    ...mapState(CONFIGURATION_STORE, [
      "shopify_config",
    ]),
  },
  methods: {
    ...mapMutations(ADMIN, {
      toggle_menu: TOGGLE_MENU,
      update_active_icon: UPDATE_ACTIVE_SIDE_MENU_ICON
    })
  }
}
</script>

<style lang="scss" scoped>
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  $blue-icon-color: #0066cc;
  $orange-icon-color: #E18F47;
  $green-icon-color: #3CB371;
  $red-icon-color: #D74949;
  $grey-icon-color: #2C3539;

  .side-menu {
    > .md-list {
      padding: 0;
    }

    &__module-expand {
      > .md-list-item-expand {
        background-color: $material-grey--light;

        > .md-list-item-content {
          i, svg {
            color: $black--light;
            fill: $black--light;
          }
        }
      }

      .md-list {
        background-color: $pure-white;
      }
    }

    &__support {
      button {
        background-color: $material-grey--light;
      }
      .md-ripple {
        i, svg {
          color: $black--light;
          fill: $black--light;
        }
      }
    }

    &__products-section-icons {
      color: $red-icon-color !important;

      svg {
        fill: $pure-white;
        stroke: $red-icon-color !important;
      }
    }

    &__content-section-icons {
      fill: $pure-white;
      stroke: $blue-icon-color;

      svg {
        fill: $pure-white;
        stroke: $blue-icon-color !important;
      }

      &--about {
        svg {
          fill: $blue-icon-color !important;
        }
      }
    }

    &__config-section-icons {
      color: $green-icon-color !important;
      fill: $green-icon-color;

      svg, path, line {
        fill: $green-icon-color !important;
        stroke: $green-icon-color !important;
      }
    }

    &__financials-section-icons {
      color: $grey-icon-color !important;

      svg {
        fill: $grey-icon-color !important;
      }
    }

    &__social-media-section-icons {
      color: $orange-icon-color !important;
      fill: $orange-icon-color;

      path {
        fill: $orange-icon-color !important;
      }
    }

    &__active-icon{
      background-color: #E8E8E8 !important;
    }
  }

  a {
    color: $pure-black;
  }

  svg {
    height: auto;
    width: 100%;
  }

</style>

<style lang="scss">
  @use "../../../../styles/_admin-constants" as *;

  .md-drawer.md-persistent-mini .side-menu__support .md-list-item-content {
    padding-left: 23px;
  }
  .md-drawer.md-persistent-mini.md-active .side-menu__support .md-list-item-content {
    padding-left: $material-size;
  }
  .md-list-expand .md-list-item-content {
    padding-left: 23px !important;
  }
</style>
