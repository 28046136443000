export default [
  "read_analytics",
  "read_customers",
  "write_customers",
  "write_inventory",
  "read_inventory",
  "read_orders",
  "write_orders",
  "read_product_listings",
  "write_product_listings",
  "read_products",
  "write_products",
  "read_shopify_payments_payouts",
  "read_discounts",
  "write_discounts"
]
