import { check_shopify_API_access } from "../constants/endpoints/firebase"
import shopify_access_scopes from "../constants/shopify_access_scopes"

export default async shopify_config => {
  let shopify_data
  if (shopify_config.shopify_secret.API_key) {
    const { data } = await check_shopify_API_access(shopify_config)
    shopify_data =  data
  }

  return {
    is_shopify_configured_correctly: shopify_data ?
      shopify_access_scopes.every(scope => shopify_data.map(({ handle }) => handle).includes(scope)) :
      false,
    shopify_access_scopes: shopify_data
  }
}
