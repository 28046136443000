function get_remaining_time(endtime) {
  const total = (endtime * 1000) - Date.parse(new Date());
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  
  return {
    total,
    days: days < 0 ? 0 : days,
    hours: hours < 0 ? 0 : hours,
    minutes: minutes < 0 ? 0 : minutes,
  };
}

function init_countdown_clock() {
  const that = this
  const endtime = Number(this.free_trial_end)

  function updateClock() {
    const time = get_remaining_time(endtime);

    that.end_days = ("0" + time.days).slice(-2);
    that.end_hours = ("0" + time.hours).slice(-2);
    that.end_minutes = ("0" + time.minutes).slice(-2);

    if (time.total <= 0) {
      clearInterval(timeinterval);
    }
  }

  updateClock();

  if (!this.end_date_calculated) this.end_date_calculated = true

  const timeinterval = setInterval(updateClock, 60000); // Update every minute
}

export default init_countdown_clock
