<template>
  <div
    class="quick-links"
    id="quick-links"
    :class="{
      'quick-links--hidden': quick_links_hidden
    }"
  >
    <span>{{ $translate("admin_content.quick_link_titles.title") }}:</span>
    <md-button class="md-icon-button md-dense md-primary" @click="toggle_quick_links">
      <md-tooltip md-direction="top">{{ $translate(quick_links_hidden ? "show" : "hide") }}</md-tooltip>
      <md-icon>{{ quick_links_hidden ? "arrow_drop_down" : "arrow_drop_up"}}</md-icon>
    </md-button>
    <div class="quick-links__wrapper">
      <div>
        <md-chip
          v-for="({ show, tooltip, link, icon, title }) in quick_links"
          :key="link"
          v-show="show"
          class="quick-link md-primary"
          :class="{ 'md-elevation-3': quick_links_hidden }"
          @click="() => $router.push(link)"
        >
          {{ $translate(title) }}
          <md-tooltip v-if="!quick_links_hidden" md-direction="bottom">{{ $translate(tooltip) }}</md-tooltip>
          <md-icon>{{ icon }}</md-icon>
        </md-chip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { event_hub } from '../../../../main';
import { imported, orders, shipping, popular_products, style_config, about_page, USER_STORE, SHOW_QUICK_LINKS } from "../../constants/others_constants";

const quick_links_local_storage_name = "quick_links_hidden";

export default {
  data() {
    return {
      quick_links_hidden: false
    };
  },
  computed: {
    ...mapState(USER_STORE, ["permissions"]),
    quick_links() {
      return [
        {
          show: this.permissions.products.module,
          tooltip: "admin_content.quick_link_tooltips.products",
          title: "admin_content.quick_link_titles.products",
          icon: "widgets",
          link: `/admin/products/${imported}`
        },
        {
          show: this.permissions.finance.sections.orders.module,
          tooltip: "admin_content.quick_link_tooltips.orders",
          title: "admin_content.quick_link_titles.orders",
          icon: "view_list",
          link: `/admin/finance/${orders}`
        },
        {
          show: this.permissions.configuration.sections.shipping.module,
          tooltip: "admin_content.quick_link_tooltips.shipping",
          title: "admin_content.quick_link_titles.shipping",
          icon: "local_shipping",
          link: `/admin/configuration/${shipping}`
        },
        {
          show: this.permissions.layout_and_content.module,
          tooltip: "admin_content.quick_link_tooltips.popular_products",
          title: "admin_content.quick_link_titles.popular_products",
          icon: "hotel_class",
          link: `/admin/content/${popular_products}`
        },
        {
          show: this.permissions.configuration.sections.styling.module,
          tooltip: "admin_content.quick_link_tooltips.style_config",
          title: "admin_content.quick_link_titles.style_config",
          icon: "format_paint",
          link: `/admin/configuration/${style_config}`
        },
        {
          show: this.permissions.layout_and_content.module,
          tooltip: "admin_content.quick_link_tooltips.about_page",
          title: "admin_content.quick_link_titles.about_page",
          icon: "info",
          link: `/admin/content/${about_page}`
        },
      ]
    }
  },
  created() {
    const should_hide_quick_links = window.localStorage.getItem(quick_links_local_storage_name, this.quick_links_hidden)
    this.quick_links_hidden = should_hide_quick_links === "true"

    event_hub.$on(SHOW_QUICK_LINKS, () => {
      this.quick_links_hidden = false;
    })
  },
  destroyed() {
    event_hub.$off(SHOW_QUICK_LINKS)
  },
  methods: {
    toggle_quick_links() {
      this.quick_links_hidden = !this.quick_links_hidden
      window.localStorage.setItem(quick_links_local_storage_name, this.quick_links_hidden)
    }
  }
}
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_admin-constants" as *;

.quick-link {
  cursor: pointer;

  i {
    margin-left: $half-default-size;
  }

  &s {
    position: relative;
    padding: $default-size 0 0;
    margin-bottom: $half-default-size;
    transition: $material-transition;

    &--hidden {
      margin-bottom: 0;
      transform: translateY(-#{$double-default-size});
      z-index: 1;

      .md-button {
        position: absolute;
        left: 0;
        top: 20px;
        transform: translateY(calc(#{$half-default-size}));

        &:hover {
          transform: translateY(calc(#{$default-size} + 2px));
        }
      }

      .md-chip {
        &:hover {
          transform: translateY(calc(#{$default-size} + 2px));
        }
      }

      .quick-links__wrapper {
        height: 55px;
      }
    }

    &__wrapper {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      column-gap: $half-default-size;
      width: calc(100% - #{$material-button-dense} - #{$half-default-size});
      overflow-x: hidden;
      overflow-y: hidden;
      height: $material-button-dense;
      white-space: nowrap;
      top: $default-size;
      left: calc(#{$material-button-dense} + #{$half-default-size});

      @media (max-width: $material-medium-breakpoint) {
        overflow-x: auto;
      }

      &:hover {
        overflow-x: auto;
      }
    }

    span {
      position: absolute;
      top: 0;
      left: 10px;
      font-size: 10px;
      font-weight: bold;
    }
  }
}
</style>
