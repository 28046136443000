import store from "../../../../store";
import { translate } from "../../../Shared/translator"
import { USER_STORE_TOUR_TOGGLE } from "../../stores/Admin/user/constants";
import { USER_STORE } from "../others_constants";
import { tour_buttons } from "./default_setups"

export const new_user_store_tour = async tour_instance => [
  {
    element: "#products-section-menu-tour",
    title: await translate("store_setup.tour.products_menu_tour.title"),
    content: await translate("store_setup.tour.products_menu_tour.content"),
    placement: "right-start",
    ...await tour_buttons(true)
  },
  {
    element: "#store-layout-menu-tour",
    title: await translate("store_setup.tour.store_layout_menu_tour.title"),
    content: await translate("store_setup.tour.store_layout_menu_tour.content"),
    placement: "right-start",
    ...await tour_buttons()
  },
  {
    element: "#store-config-menu-tour",
    title: await translate("store_setup.tour.store_config_menu_tour.title"),
    content: await translate("store_setup.tour.store_config_menu_tour.content"),
    placement: "right-start",
    ...await tour_buttons()
  },
  {
    element: "#finance-menu-tour",
    title: await translate("store_setup.tour.finance_menu_tour.title"),
    content: await translate("store_setup.tour.finance_menu_tour.content"),
    placement: "right-start",
    // For when we need to open a menu item
    // onNext: () => {
    //   if (!document.querySelector("#store-config-menu-tour > div").classList.contains("md-active")) {
    //     tour_instance.isPaused = true;
    //     tour_instance.showLoader();

    //     document.querySelector("#store-config-menu-tour > div > div").click()

    //     setTimeout(() => tour_instance.moveNext(), 300)
    //   }
    // },
    ...await tour_buttons()
  },
  {
    element: "#quick-links",
    title: await translate("store_setup.tour.quick_links_menu_tour.title"),
    content: await translate("store_setup.tour.quick_links_menu_tour.content"),
    placement: "bottom",
    ...await tour_buttons()
  },
  {
    element: "#store-preview-toggle",
    title: await translate("store_setup.tour.store_preview.title"),
    content: await translate("store_setup.tour.store_preview.content"),
    placement: "bottom-end",
    ...await tour_buttons(),
    btnNext: {
      textColor: "#fff",
      backgroundColor: "#448aff",
      text: await translate("finish"),
    },
    onNext: () => {
      store.commit(`${USER_STORE}/${USER_STORE_TOUR_TOGGLE}`, { value: false, type: "store" }, { root: true })
    },
  }
];

export const initial_marketplace_tour = async tour_instance => [
  {
    element: "#all-collections-tour",
    title: await translate("store_setup.tour.marketplace_collections_tour.title"),
    content: await translate("store_setup.tour.marketplace_collections_tour.content"),
    placement: "bottom-start",
    ...await tour_buttons(true)
  },
  {
    element: "#products-preview-tour",
    title: await translate("store_setup.tour.products_preview_tour.title"),
    content: await translate("store_setup.tour.products_preview_tour.content"),
    placement: "top-start",
    onNext: () => {
      const product_el = document.querySelector("#products-preview-tour > .product-detail")

      if (product_el) {
        product_el.click()

        tour_instance.isPaused = true;

        setTimeout(() => document.querySelector("#marketplace-product-checkbox").scrollIntoView({
          block: "center"
        }), 200)
        setTimeout(() => tour_instance.moveNext(), 1000)
      }
    },
    ...await tour_buttons(),
  },
  {
    element: "#marketplace-product-checkbox",
    title: await translate("store_setup.tour.marketplace_checkbox_tour.title"),
    content: await translate("store_setup.tour.marketplace_checkbox_tour.content"),
    placement: "top-start",
    onNext: () => {
      tour_instance.isPaused = true;

      setTimeout(() => document.querySelector("#marketplace-categorization-button").scrollIntoView({
        block: "center"
      }), 100)
      setTimeout(() => tour_instance.moveNext(), 500)
    },
    ...await tour_buttons(),
  },
  {
    element: "#marketplace-categorization-button",
    title: await translate("store_setup.tour.marketplace_categorization_tour.title"),
    content: await translate("store_setup.tour.marketplace_categorization_tour.content"),
    placement: "top-start",
    ...await tour_buttons(),
    btnNext: {
      textColor: "#fff",
      backgroundColor: "#448aff",
      text: await translate("finish"),
    },
    onNext: () => {
      store.commit(`${USER_STORE}/${USER_STORE_TOUR_TOGGLE}`, { value: false, type: "marketplace" }, { root: true })
    },
  },
];
